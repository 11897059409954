import React from 'react';

import styled from '@emotion/styled';

import LoadingIndicatorBase from 'src/components/LoadingIndicator';
import { Props as ProfileImageProps } from 'src/components/ProfileImage';
import ProfileImage from 'src/components/ProfileImage';
import { hexToRgb } from 'src/utils/common';

type FillAudioLevelDirection = 'horizontal' | 'vertical';

const Layout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const CameraOffImage = styled(ProfileImage)<{ dimmed: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  ${({ dimmed }) => dimmed && 'filter: brightness(60%);'}
`;

export const BlurLayout = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
`;

const LoadingIndicator = styled(LoadingIndicatorBase)`
  ${({ theme }) => theme.screenSize.mobile} {
    width: 32px;
    height: 32px;
  }
`;
export const CameraOffLayout = styled(BlurLayout)`
  padding: calc(131 / 840 * 100%) calc(100 / 778 * 100%);
  ${({ theme }) => theme.screenSize.tablet} {
    padding: calc(24 / 360 * 100%);
  }
  box-sizing: border-box;
  overflow: hidden;
`;

export const CameraOffLayoutMic = styled.img`
  width: 56px;
  height: 56px;
  ${({ theme }) => theme.screenSize.mobile} {
    width: 40px;
    height: 40px;
  }
`;

const CameraOffLayoutCircle = styled.div<{
  idx: number;
  fillDirection: FillAudioLevelDirection;
}>`
  position: absolute;
  ${({ fillDirection, idx }) =>
    fillDirection === 'horizontal'
      ? `width: calc(${idx} / 7 * 100%)`
      : `height: calc(${idx} / 6 * 100%)`};
  ${({ theme }) => theme.screenSize.tablet} {
    ${({ fillDirection, idx }) =>
      fillDirection === 'horizontal'
        ? `width: calc(${idx} / 6 * 100%)`
        : `height: calc(${idx} / 5 * 100%)`};
  }
  aspect-ratio: 1 / 1;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(${({ theme }) => hexToRgb(theme.color.green500)}, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

export const CameraOffLayoutMicWrap = styled(CameraOffLayoutCircle)<{
  isSpeaking: boolean;
  fillDirection: FillAudioLevelDirection;
}>`
  ${({ fillDirection }) =>
    fillDirection === 'horizontal' ? 'width' : 'height'}: 18%;
  ${({ theme }) => theme.screenSize.tablet} {
    ${({ fillDirection }) =>
      fillDirection === 'horizontal' ? 'width' : 'height'}: 18%;
  }
  opacity: 1;
  border: 4px solid rgba(${({ theme }) => hexToRgb(theme.color.green500)}, 0.5);
  background-color: rgba(${({ theme }) => hexToRgb(theme.color.green500)}, 0.5);
  ${({ isSpeaking, theme }) =>
    isSpeaking &&
    `
    border-color: rgba(${hexToRgb(theme.color.white)},0.6);
  `}
`;

interface Props extends Required<Pick<ProfileImageProps, 'src'>> {
  cameraEnabled: boolean;
  isLoading: boolean;
  audioLevel: number;
  dimmed?: boolean;
  className?: string;
  fillAudioLevelDirection?: FillAudioLevelDirection;
}
const VideoLayer: React.FC<Props> = ({
  audioLevel,
  isLoading,
  cameraEnabled,
  className,
  dimmed = false,
  fillAudioLevelDirection = 'horizontal',
  ...props
}) => {
  const loadingLayout =
    isLoading && cameraEnabled ? (
      <BlurLayout>
        <LoadingIndicator size={56} />
      </BlurLayout>
    ) : null;
  const cameraOffLayout =
    !loadingLayout && !cameraEnabled ? (
      <CameraOffLayout>
        {Array.from(Array(audioLevel))
          .map((_, i, arr) => arr.length - i + 1)
          .map((key) => (
            <CameraOffLayoutCircle
              key={`CameraOffLayoutCircle-${key}`}
              idx={key}
              fillDirection={fillAudioLevelDirection}
            />
          ))}
        <CameraOffLayoutMicWrap
          isSpeaking={audioLevel >= 1}
          idx={0}
          fillDirection={fillAudioLevelDirection}
        >
          <CameraOffLayoutMic src='/images/icons/icVoice.svg' alt='Op Mic' />
        </CameraOffLayoutMicWrap>
      </CameraOffLayout>
    ) : null;
  if (!cameraOffLayout && !loadingLayout && !dimmed) {
    return null;
  }
  return (
    <Layout className={className}>
      <CameraOffImage
        {...props}
        dimmed={!loadingLayout && !cameraOffLayout && dimmed}
        alt='profile image'
      />
      {loadingLayout}
      {cameraOffLayout}
    </Layout>
  );
};

export default VideoLayer;
