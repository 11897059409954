import { FC } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';

import Image from 'src/components/Image';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { isDecoSupportedAtom, isLoadingEffectAtom } from 'src/stores/deco/atom';
import { DEFAULT_CLIENT_EFFECT, DecoEffect } from 'src/types/Deco';
import { mouseHoverCss } from 'src/utils/styled';

const LabelWrap = styled.div`
  z-index: 2;
  position: absolute;
  top: 4px;
  ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.color.red500};
  border-radius: 100px;

  ${({ theme }) => theme.screenSize.tablet} {
    top: 3px;
    ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 5px;
  }
`;

const Filter = styled.button`
  width: 100%;
  height: 100px;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${({ theme }) => theme.color.gray100__dkGray150};

  ${({ theme }) => theme.screenSize.tablet} {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
`;
const FilterButtonWrap = styled.div<{ active?: boolean; available: boolean }>`
  position: relative;
  border-radius: 10px;
  flex-shrink: 0;
  overflow: hidden;
  width: 74px;
  height: 74px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-items: center;
  border: ${({ theme, active, available }) =>
    active && available
      ? `3px solid ${theme.color.green500}`
      : `1px solid ${theme.color.gray100__dkGray150}`};

  ${({ available }) =>
    !available &&
    css`
      opacity: 40%;
    `}

  ${({ active, available, theme }) =>
    !active &&
    available &&
    mouseHoverCss(`
      border: 1px solid ${theme.color.green500};
    `)}

  ${LabelWrap} {
    ${({ active, theme }) =>
      active &&
      css`
        top: 2px;
        ${theme.isRtl ? 'left' : 'right'}: 2px;
      `}
  }

  ${({ theme }) => theme.screenSize.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    border-radius: 50%;
    border: ${({ theme, active }) =>
      active
        ? `3px solid ${theme.color.green500}`
        : `1px solid ${theme.color.white__dkBlack}`};

    ${LabelWrap} {
      ${({ active, theme }) =>
        active &&
        css`
          top: 1px;
          ${theme.isRtl ? 'left' : 'right'}: 3px;
        `}
    }
  }
`;

const FilterIconImage = styled(Image)`
  width: 74px;
  height: 74px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 72.32px;
    height: 64px;
  }
`;

const LoadingDim = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;

  ${({ theme }) => theme.screenSize.tablet} {
    border-radius: 50%;
  }
`;

const LabelText = styled.p`
  font-size: 10px;
  font-weight: 700;
  line-height: 13px; /* 130% */
  color: ${({ theme }) => theme.color.gray900__dkGray970};
`;

interface Props {
  key: string;
  onClick: () => void;
  active: boolean;
  thumbnail: string;
  isNew: boolean;
  effectId: DecoEffect['effectId'];
}
const EffectButton: FC<Props> = ({
  active,
  onClick,
  thumbnail,
  isNew,
  effectId,
}) => {
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const isLoadingEffect = useAtomValue(isLoadingEffectAtom);

  const isLoading =
    effectId !== DEFAULT_CLIENT_EFFECT.effectId && isLoadingEffect;

  return (
    <FilterButtonWrap active={active} available={!!isDecoSupported}>
      {isNew && (
        <LabelWrap>
          <LabelText>N</LabelText>
        </LabelWrap>
      )}
      <Filter onClick={onClick}>
        <FilterIconImage
          src={thumbnail}
          isBig={false}
          supportImageSet={false}
        />
      </Filter>
      {isDecoSupported && active && isLoading && (
        <LoadingDim>
          <LoadingIndicator size={24} />
        </LoadingDim>
      )}
    </FilterButtonWrap>
  );
};

export default EffectButton;
