import { FC } from 'react';

import ChatLayout from 'src/components/ChatLayout';
import ChatNickName from 'src/components/ChatNickName';
import { Chat as ChatType } from 'src/types/Chat';
import { filterPrivacy } from 'src/utils/common';

import { NickNameWrap, TextWrap } from './style';

type ChatProps = ChatType;

const Chat: FC<ChatProps> = (props) => {
  if (!(props.type === 'azar_message')) {
    return null;
  }
  const chat = props;
  if (!chat.message || !chat.sender) {
    return null;
  }
  const [name, iconSource] = [
    chat.sender.simpleName,
    chat.sender.profileImageUrl,
  ];

  return (
    <ChatLayout iconSource={iconSource}>
      <NickNameWrap>
        <ChatNickName label={name} />
      </NickNameWrap>
      <TextWrap>{filterPrivacy(chat.message)}</TextWrap>
    </ChatLayout>
  );
};

export { Chat };
export type { ChatProps };
