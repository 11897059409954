import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import IcCloseSvg from 'src/assets/images/icDecoClose.svg';
import ModalLayoutBase, {
  MobileModalType,
  ModalContent as ModalContentBase,
  ModalHeader as ModalHeaderBase,
  bottomModal,
} from 'src/components/ModalLayout';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import {
  decoVidRefAtom,
  effectGroupMapAtom,
  handleUnsupportedDecoAccessAtom,
  isDecoSupportedAtom,
  isShowDecoCoachMarkAtom,
  isShowDecoRedDotAtom,
  selectDecoEffectAtom,
} from 'src/stores/deco/atom';
import {
  eventMatchPageAtom,
  handleDecoStreamVideoAtom,
  handleStreamVideoAtom,
  localVideoToDecoViewAtom,
} from 'src/stores/match/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { DecoEffect } from 'src/types/Deco';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalNavButtonType, ModalType } from 'src/types/Modal';

import DesktopEffects from './DesktopEffects';
import MobileEffects from './MobileEffects';
import { mouseHoverCss } from 'src/utils/styled';

const ModalLayout = styled(ModalLayoutBase)`
  max-height: calc(var(--vh) * 90);
  max-width: 458px;

  ${({ theme }) => theme.screenSize.tablet} {
    height: 212px;
    z-index: 2;
    max-width: var(--100vw);
    ${bottomModal}// 태블릿 뷰에서도 Bottom 모달 레이아웃 적용 목적
  }
`;
const Video = styled.canvas`
  object-fit: cover;
  height: 268px;
  transform: scaleX(-1);
  border-radius: 8px;
  margin: 16px 0;
  ${({ theme }) => theme.screenSize.tablet} {
    display: none;
  }
`;
const ModalTitle = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  flex: 1;
  color: ${({ theme }) => theme.color.white};
`;
const ModalContent = styled(ModalContentBase)`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.screenSize.tablet} {
    padding: 8px 0 0 0;
  }
`;
const ModalHeader = styled(ModalHeaderBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const CloseButton = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: transparent;
  position: absolute;
  z-index: 10;
  top: 16px;
  border-radius: 20px;
  ${({ theme }) => (theme.isRtl ? 'left' : 'right')}:16px;
  ${({ theme }) => theme.screenSize.tablet} {
    position: fixed;
  }
  ${({ theme }) =>
    mouseHoverCss(`
      background-color: ${theme.color.gray100__dkGray150};
      opacity: 0.4;
    `)}
`;

const EffectsModal = () => {
  const decoVideoRef = useAtomValue(decoVidRefAtom);
  const device = useCssMediaDevice();
  const setIsShowRedDot = useSetAtom(isShowDecoRedDotAtom);
  const setIsShowCoachMark = useSetAtom(isShowDecoCoachMarkAtom);
  const handleDecoEffect = useSetAtom(handleDecoStreamVideoAtom);
  const selectEffect = useSetAtom(selectDecoEffectAtom);
  const pushEvent = useEvent();
  const closeModal = useSetAtom(closeModalAtom);
  const eventMatchPage = useAtomValue(eventMatchPageAtom);
  const handleStreamVideo = useSetAtom(handleStreamVideoAtom);
  const localVideoToDecoView = useAtomValue(localVideoToDecoViewAtom);
  const [isShowCloseButton, setIsShowCloseButton] = useState(false);
  const { t } = useTranslation();
  const isDesktop = device === 'desktop';
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const effectGroupMap = useAtomValue(effectGroupMapAtom);
  const handleUnsupportedDecoAccess = useSetAtom(
    handleUnsupportedDecoAccessAtom
  );

  const handleClickEffect = (effect: DecoEffect) => {
    if (!isDecoSupported) {
      handleUnsupportedDecoAccess();
      return;
    }

    selectEffect(effect);
    pushEvent({
      eventType: EVENT_TYPE.DECO,
      eventName: EVENT_NAME.CLICK_DECO_STUDIO_SELECT_ITEM,
      eventParams: {
        action_category: 'click',
        tab: 'mirror',
        page: eventMatchPage,
        target: 'deco',
        effectId: effect.effectId,
        groupId: effectGroupMap.get(effect.effectId),
      },
    });
  };

  useEffect(() => {
    setIsShowRedDot(false);
    setIsShowCoachMark(false);
  }, [setIsShowRedDot, setIsShowCoachMark]);

  useEffect(() => {
    handleDecoEffect();
    handleStreamVideo();
  }, [handleDecoEffect, handleStreamVideo]);

  useEffect(
    () => () => {
      localVideoToDecoView?.stopRendering();
    },
    [localVideoToDecoView]
  );

  const CloseComponent = () => (
    <CloseButton onClick={() => closeModal(ModalType.DECO_STUDIO)}>
      <IcCloseSvg />
    </CloseButton>
  );

  return (
    <ModalLayout
      isDim={isDesktop}
      navButtonType={ModalNavButtonType.BACK}
      closeWhenClickOutside={isDesktop}
      mobileType={MobileModalType.BOTTOM_SHEET}
      onAnimationEnd={() => {
        setIsShowCloseButton(true);
      }}
    >
      {(isShowCloseButton || isDesktop) && <CloseComponent />}
      {isDesktop && (
        <ModalHeader>
          <ModalTitle>{t('deco_studio_title')}</ModalTitle>
        </ModalHeader>
      )}
      <ModalContent>
        <Video ref={decoVideoRef}></Video>
        {isDesktop ? (
          <DesktopEffects handleClickEffect={handleClickEffect} />
        ) : (
          <MobileEffects handleClickEffect={handleClickEffect} />
        )}
      </ModalContent>
    </ModalLayout>
  );
};

export default EffectsModal;
