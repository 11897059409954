import { FC, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';

import ChatInputDesktop from 'src/components/ChatInputDesktop';
import ChatInputMobile from 'src/components/ChatInputMobile';
import Chatting from 'src/components/Chatting';
import NextMatchButtonBase from 'src/components/Match/Matching/NextMatchButtonBase';
import ToggleCameraButtonBase from 'src/components/Match/Matching/Toolbar/CameraOffButton';
import DecoFilterButton from 'src/components/Match/Matching/Toolbar/DecoFilterButton';
import useClickOutside from 'src/hooks/useClickOutside';
import useEvent from 'src/hooks/useEvent';
import {
  chatListAtom,
  isBonusMatchAtom,
  isShowMatchChatAtom,
  isVideoObjectFitCoverAtom,
  mobileLayoutAtom,
  nextMatchDisableShowAtom,
  sendChatAtom,
  statusAtom,
  swipeDisableSecondAtom,
} from 'src/stores/match/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { hexToRgb } from 'src/utils/common';
import SystemMessageBase from 'src/components/SystemMessage';
import color from 'src/styles/color';
import { STATUS } from 'src/types/Match';
import {
  RedDot,
  ToolButton,
  ToolButtonGroup as ToolButtonGroupBase,
  ToolButtonIcon,
} from 'src/components/Match/Matching/Toolbar/styles';
import RandomEffectButton from 'src/components/Match/Matching/Toolbar/RandomEffectButton';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';

const RootStyle = styled.div`
  text-align: ${({ theme }) => (theme.isRtl ? 'right' : 'left')};
  flex: 1;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 16px 16px;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  mask-image: linear-gradient(
    to top,
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 1),
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 1),
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 1),
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 0)
  );
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: top, bottom;
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 1),
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 1),
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 1),
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 0)
  );
  -webkit-mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: top, bottom;
`;
const Gradient = styled.div<{ isShow: boolean }>`
  background: linear-gradient(
    180deg,
    rgba(${({ theme }) => hexToRgb(theme.color.gray60__dkGray100)} 0.2),
    rgba(34, 34, 34, 0) 100%
  );
  height: 80px;
  width: 100%;
  ${({ theme }) => theme.screenSize.tablet} {
    position: absolute;
    height: 307.2px;
    left: 0px;
    right: 0px;
    bottom: -307.2px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.0001) 0%,
      rgba(0, 0, 0, 0.4) 98.59%
    );
    transform: matrix(1, 0, 0, -1, 0, 0);
  }
  ${({ theme }) => theme.screenSize.tablet} {
    transition: opacity 0.3s linear;
    opacity: 0;
    ${({ isShow }) =>
      isShow &&
      `
      opacity: 1 !important;
    `}
  }
`;

const ChatBox = styled.div<{ isShow: boolean }>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px 0px 16px;
  gap: 16px;
  max-height: 100%;
  position: relative;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  ${({ theme }) => theme.screenSize.tablet} {
    transition: opacity 0.3s linear;
    opacity: 0;
    ${({ isShow }) =>
      isShow &&
      `
      opacity: 1 !important;
    `}
  }
`;
const ChatListWrap = styled.div`
  width: 100%;
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;
const InputWrap = styled.div`
  width: 100%;
`;

const InputMobileRow = styled.div`
  display: none;
  height: 40px;
  gap: 8px;
  ${({ theme }) => theme.screenSize.tablet} {
    display: flex;
  }
`;

const IcChevronRight = styled(
  dynamic(() => import('src/assets/images/icChevronRight.svg'))
)`
  ${({ theme }) => theme.isRtl && 'transform: scaleX(-1);'}
  fill: ${({ theme }) => theme.color.white};
  width: 16px;
  height: 16px;
`;

const NextMatchButtonMobile = styled(NextMatchButtonBase)`
  display: none;
  ${({ theme }) => theme.screenSize.tablet} {
    position: relative;
    display: flex;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 70px;
    height: 100%;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    min-width: fit-content;
    padding: 10px 14px 9px;
    color: ${({ theme }) => theme.color.white};
    :disabled {
      ${IcChevronRight} {
        fill: ${({ theme }) => theme.color.gray700__dkGray700};
      }
    }
  }

  ${({ theme }) => theme.screenSize.mobile} {
    ${({ theme }) => (theme.isRtl ? 'margin-right' : 'margin-left')} : auto;
  }
`;

const MatchChatNotice = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  margin-bottom: 16px;
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 13px;
    line-height: 16px;
    color: ${({ theme }) => theme.color.white};
    margin-bottom: 10px;
  }
  > a {
    color: ${({ theme }) => theme.color.green500};
    text-decoration: underline;
  }
`;

const SystemMessage = styled(SystemMessageBase)`
  margin: 0 -4px;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 10px;
`;

const ChattingWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
`;

const NextIcon = styled.img`
  ${({ theme }) =>
    theme.isRtl &&
    `
    transform: scale(-1);
  `}
`;

const ChatInputDesktopWrap = styled.div`
  ${({ theme }) => theme.screenSize.tablet} {
    display: none;
  }
`;

const ToolButtonGroup = styled(ToolButtonGroupBase)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  background-color: rgba(${({ theme }) => hexToRgb(theme.color.black)}, 0.3);
  border-radius: 100px;

  &:has(> :nth-child(2)) {
    padding: 0px 16px;
    ${ToolButton} {
      width: 20px;
    }
  }
  ${ToolButton} {
    ${ToolButtonIcon} {
      width: 20px;
      height: 20px;
    }
    ${RedDot} {
      ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: -9px;
    }
  }
`;

const ToggleChatButtonGroup = styled(ToolButtonGroup)`
  display: none;
  ${({ theme }) => theme.screenSize.mobile} {
    display: flex;
  }
`;

const ToggleCameraButton = styled(ToggleCameraButtonBase)`
  ${({ theme }) => theme.screenSize.tablet} {
    padding: 6px;
    width: 40px;
    height: 40px;
    ${ToolButtonIcon} {
      width: 20px;
      height: 20px;
    }
  }
`;

const NextMatchDisableCount = styled.span`
  // NextMatchButtonMobileDesc 를 visibility로 숨겨서 absolute
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const NextMatchButtonMobileText = styled.p``;

const NextMatchButtonMobileDesc = styled.div<{ isShow: boolean }>`
  // NextMatchDisableCount 나올 때도 width 유지하기위해 visibility 사용
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
  gap: 2px;
  ${({ theme }) => (theme.isRtl ? 'padding-right' : 'padding-left')}: 6px;

  // 스몰뷰 대응
  @media (max-width: 320px) {
    padding-right: 6px;
    padding-left: 6px;
    ${NextMatchButtonMobileText} {
      display: none;
    }
  }
`;

interface ChatListProps {
  nextMatch: () => void;
}

const ChatList: FC<ChatListProps> = ({ nextMatch }) => {
  const chatList = useAtomValue(chatListAtom);
  const sendChat = useSetAtom(sendChatAtom);
  const [mobileLayout, setMobileLayout] = useAtom(mobileLayoutAtom);
  const [text, setText] = useState('');
  const { t } = useTranslation();
  const [isShow, setIsShow] = useAtom(isShowMatchChatAtom);
  const status = useAtomValue(statusAtom);
  const [isFocus, setIsFocus] = useState(false);
  const nextMatchDisableShow = useAtomValue(nextMatchDisableShowAtom);
  const swipeDisableSecond = useAtomValue(swipeDisableSecondAtom);
  const isBonusMatch = useAtomValue(isBonusMatchAtom);
  const [isVideoObjectFitCover, setIsVideoObjectFitCover] = useAtom(
    isVideoObjectFitCoverAtom
  );
  const device = useCssMediaDevice();
  const isMobile = device === 'mobile';

  const boxRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const handleClickClose = () => {
    setIsShow(false);
  };
  const contentRef = useClickOutside<HTMLDivElement>(handleClickClose);

  const scrollToBottom = () => {
    if (listRef.current?.clientHeight) {
      boxRef.current?.scrollTo({ top: listRef.current?.clientHeight });
    }
  };

  const handleSubmit = () => {
    if (status !== STATUS.MATCHED || !text) {
      return;
    }
    sendChat(text);
    setText('');
  };

  const targetMobileLayout = mobileLayout === 'DEFAULT' ? 'HALF' : 'DEFAULT';
  const pushEvent = useEvent();

  const onClickChangeLayout = () => {
    pushEvent({
      eventType: EVENT_TYPE.VIEW,
      eventName: EVENT_NAME.MIRROR_CLICK_SPLIT,
      eventParams: {
        action_category: 'click',
        tab: 'mirror',
        page: 'match',
        target: 'split',
        width: 'short',
        letterbox: isVideoObjectFitCover ? 'off' : 'on',
        split: targetMobileLayout === 'DEFAULT' ? 'off' : 'on',
      },
    });
    setMobileLayout(targetMobileLayout);
  };

  useEffect(() => {
    setText('');
  }, [status]);

  useEffect(() => {
    if (status === STATUS.MATCHED) {
      setIsShow(true);
    }
  }, [status, setIsShow]);

  useEffect(scrollToBottom, [chatList]);
  useEffect(() => {
    if (!text && isShow) {
      const timer = setTimeout(() => {
        setIsShow(false);
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [text, isShow, setIsShow]);
  const handleClickObjectFit = () => {
    pushEvent({
      eventType: EVENT_TYPE.VIEW,
      eventName: EVENT_NAME.MIRROR_CLICK_LETTERBOX,
      eventParams: {
        action_category: 'click',
        tab: 'mirror',
        page: status === STATUS.INITIAL ? 'main' : 'match',
        target: 'letterbox',
        width: 'short',
        letterbox: isVideoObjectFitCover ? 'on' : 'off',
        split: mobileLayout === 'DEFAULT' ? 'off' : 'on',
      },
    });
    setIsVideoObjectFitCover(!isVideoObjectFitCover);
  };

  return (
    <RootStyle
      role='presentation'
      ref={contentRef}
      onClick={() => setIsShow(true)}
    >
      <Gradient isShow={isShow} />
      <ChatBox ref={boxRef} isShow={isShow}>
        <ChatListWrap data-testid='Match-ChatList-wrap' ref={listRef}>
          <MatchChatNotice
            dangerouslySetInnerHTML={{
              __html: t('VIDEOCHAT_MATCH_CHAT_GUIDELINE'),
            }}
            data-testid='live-chat-guideline'
          />
          {isBonusMatch && (
            <SystemMessage
              iconSrc={'/images/match/BonusMatch.png'}
              message={t('match_toast_bonusmatch_benefit')}
              backgroundColor={`rgba(${hexToRgb(color.green500)}, 0.7)`}
            />
          )}
          <ChattingWrap>
            {chatList.map((chat, i) => (
              <Chatting key={i} {...chat} />
            ))}
          </ChattingWrap>
        </ChatListWrap>
      </ChatBox>
      <InputWrap
        onKeyUp={(e) => e.stopPropagation()}
        role='button'
        tabIndex={0}
      >
        <InputMobileRow>
          {(isFocus || !isMobile) && (
            <ChatInputMobile
              onChangeText={setText}
              value={text}
              onSubmit={handleSubmit}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            />
          )}
          {!isFocus && (
            <>
              <ToggleChatButtonGroup>
                <ToolButton
                  onClick={() => setIsFocus(true)}
                  data-testid='mobile-chat-button'
                >
                  <ToolButtonIcon
                    src='/images/history/icMessage.svg'
                    alt='chat'
                  />
                </ToolButton>
              </ToggleChatButtonGroup>
              <ToolButtonGroup>
                <ToolButton onClick={handleClickObjectFit}>
                  <ToolButtonIcon
                    src={`/images/match/${isVideoObjectFitCover ? 'icMinimize' : 'icMaximize'}.svg`}
                    alt='next'
                  />
                </ToolButton>
                <ToolButton onClick={onClickChangeLayout}>
                  <ToolButtonIcon
                    src={`/images/match/icMobileLayout${targetMobileLayout}.svg`}
                    alt='next'
                  />
                </ToolButton>
              </ToolButtonGroup>
              <ToolButtonGroup>
                <ToggleCameraButton buttonTheme='dark' />
              </ToolButtonGroup>
              <ToolButtonGroup>
                <DecoFilterButton />
                <RandomEffectButton type='bottombar' />
              </ToolButtonGroup>
              <NextMatchButtonMobile
                onClick={nextMatch}
                data-testid='Match-Next-Mobile'
              >
                {nextMatchDisableShow && (
                  <NextMatchDisableCount data-testid='Match-Next-Mobile-disable'>
                    {swipeDisableSecond}
                  </NextMatchDisableCount>
                )}
                <NextMatchButtonMobileDesc
                  isShow={!nextMatchDisableShow}
                  data-testid='mobile-next-match-button-text'
                >
                  <NextMatchButtonMobileText>
                    {t('VIDEOCHAT_MATCH_NEXT')}
                  </NextMatchButtonMobileText>
                  <NextIcon src='/images/icons/icChevronRight.svg' alt='next' />
                </NextMatchButtonMobileDesc>
              </NextMatchButtonMobile>
            </>
          )}
        </InputMobileRow>
        <ChatInputDesktopWrap>
          <ChatInputDesktop
            onChangeText={setText}
            value={text}
            onClickSubmit={handleSubmit}
          />
        </ChatInputDesktopWrap>
      </InputWrap>
    </RootStyle>
  );
};

export default ChatList;
export type { ChatListProps };
