import { useAtom, useAtomValue } from 'jotai';

import {
  matchFiltersAtom,
  matchFilterSelectionsAtom,
} from 'src/stores/match/atoms';

const genderFilterSelector = ({ filterId }: { filterId: string }) =>
  filterId === 'GENDER_CHOICE';

const useGenderPreference = () => {
  const matchFilters = useAtomValue(matchFiltersAtom);
  const [matchFilterSelections, setMatchFilterSelections] = useAtom(
    matchFilterSelectionsAtom
  );
  const genderMatchFilter = matchFilters?.find(genderFilterSelector);
  const genderMatchFilterSelection =
    matchFilterSelections.find(genderFilterSelector);
  const genderMatchFilterOption = genderMatchFilter?.filterOptions.find(
    ({ key }) => key === genderMatchFilterSelection?.optionKey
  );

  return {
    matchFilterSelections,
    setMatchFilterSelections,
    genderMatchFilter,
    genderMatchFilterSelection,
    genderMatchFilterOption,
  };
};

export default useGenderPreference;
