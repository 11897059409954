import { FC } from 'react';

import { Nickname, RootStyle } from './style';

interface ChatNickNameProps {
  label?: string;
  nicknameMaxWidth?: number;
}

const ChatNickName: FC<ChatNickNameProps> = ({
  label,
  nicknameMaxWidth,
  ...props
}) => (
  <RootStyle {...props}>
    <Nickname width={nicknameMaxWidth}>{label}</Nickname>
  </RootStyle>
);

export { ChatNickName };
export type { ChatNickNameProps };
