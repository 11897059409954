import { useCallback, useEffect, useState } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import useEnableRemoteConfig from 'src/hooks/useEnableRemoteConfig';
import useEvent from 'src/hooks/useEvent';
import {
  handleDisconnectAtom,
  nextMatchDisableShowAtom,
  statusAtom,
  swipeDisableSecondAtom,
} from 'src/stores/match/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { STATUS } from 'src/types/Match';

const useNextMatch = () => {
  const status = useAtomValue(statusAtom);
  const swipeDisableSecond = useAtomValue(swipeDisableSecondAtom);
  const setNextMatchDisableShow = useSetAtom(nextMatchDisableShowAtom);
  const handleDisconnect = useSetAtom(handleDisconnectAtom);
  // 오른쪽 방향키 눌렀을 때 ui 상 버튼 active effect를 주기 위해
  const [isKeyPressed, setIsKeyPressed] = useState(false);
  const enableSkipConnecting = useEnableRemoteConfig('enableSkipConnecting');
  const pushEvent = useEvent();

  const nextMatch = useCallback(() => {
    if (
      status < STATUS.CONNECTING ||
      (enableSkipConnecting && status === STATUS.CONNECTING)
    ) {
      return;
    }
    if (swipeDisableSecond) {
      setNextMatchDisableShow(true);
      return;
    }
    handleDisconnect({ reconnect: true, finisher: true });
    pushEvent({
      eventName: EVENT_NAME.MIRROR__CLICK_NEXT_VIDEO_CHAT,
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventParams: {
        action_category: 'click',
        tab: 'mirror',
        page: status === STATUS.MATCHED ? 'match' : 'connecting',
        target: 'next_video_chat',
      },
    });
  }, [
    enableSkipConnecting,
    handleDisconnect,
    pushEvent,
    setNextMatchDisableShow,
    status,
    swipeDisableSecond,
  ]);

  useEffect(() => {
    const handleNextMatch = (e: KeyboardEvent) => {
      if ((e.target as HTMLInputElement)?.value) {
        return;
      }
      if (e.key === 'ArrowRight' || e.code === 'ArrowRight') {
        setIsKeyPressed(true);
        setTimeout(() => setIsKeyPressed(false), 100);
        nextMatch();
      }
    };
    window.addEventListener('keyup', handleNextMatch);

    return () => {
      window.removeEventListener('keyup', handleNextMatch);
    };
  }, [nextMatch]);

  return { nextMatch, isKeyPressed };
};

export default useNextMatch;
