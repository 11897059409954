import styled from '@emotion/styled';
import { FC } from 'react';
import { hexToRgb } from 'src/utils/common';
import Image from 'src/components/Image';

const IconWrap = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) =>
    `rgba(${hexToRgb(theme.color.white)}, 0.7)`};
  flex-shrink: 0;
`;

const Icon = styled(Image)`
  width: 16px;
  height: 16px;
`;

const Container = styled.div<{ $backgroundColor?: string }>`
  display: flex;
  align-items: center;
  padding: 4px;
  ${({ theme }) => `${theme.isRtl ? 'padding-left' : 'padding-right'}: 12px;`}
  border-radius: 16px;
  ${({ $backgroundColor, theme }) =>
    !!$backgroundColor &&
    `
    background-color: ${$backgroundColor};

    ${IconWrap} {
      background-color: ${theme.color.white};
    }
  `}
  gap: 8px;
`;

const Message = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme }) => theme.color.white};
`;

interface Props {
  iconSrc: string;
  message: string;
  className?: string;
  backgroundColor?: string;
}

const SystemMessage: FC<Props> = ({
  iconSrc,
  message,
  backgroundColor,
  className,
}) => {
  return (
    <Container className={className} $backgroundColor={backgroundColor}>
      <IconWrap>
        <Icon src={iconSrc} alt='system-message-icon' isBig={false} />
      </IconWrap>
      <Message>{message}</Message>
    </Container>
  );
};

export default SystemMessage;
