import { FC, useMemo } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import TextLogo from 'src/components/Logo/TextLogo';
import Watermark from 'src/components/Logo/Watermark';
import VideoLayerBase, {
  CameraOffImage,
  CameraOffLayout,
  CameraOffLayoutMic,
  CameraOffLayoutMicWrap,
} from 'src/components/Match/Matching/VideoLayer';
import { defaultProfileImage } from 'src/components/ProfileImage';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import {
  isOnCameraAtom,
  isVideoObjectFitCoverAtom,
  localAudioLevelAtom,
  matchUuidAtom,
  mobileLayoutAtom,
  myVideoAspectRatioAtom,
  sourceStreamAtom,
  statusAtom,
  stepAtom,
  vidRefAtom,
} from 'src/stores/match/atoms';
import { profileAtom } from 'src/stores/profile/atoms';
import color from 'src/styles/color';
import { MobileLayout, STATUS } from 'src/types/Match';
import { hexToRgb } from 'src/utils/common';

import MatchCounter from './MatchCounter';

const Container = styled.div<{
  step: number;
  status: STATUS;
  isPlayedAnimation: boolean;
  mobileLayout: MobileLayout;
  hasStream: boolean;
  isCover: boolean;
  aspectRatio: number;
}>`
  position: relative;
  height: 100%;
  ${({ theme }) => theme.screenSize.desktop} {
    overflow: hidden;
    width: 50%;
    border-radius: 20px;
  }
  ${({ theme }) => theme.screenSize.tablet} {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ status }) =>
      status === STATUS.MATCHED &&
      `
      transition-property: width, top, left, right, height, z-index;
      transition-duration: .5s;
      transition-timing-function: cubic-bezier(0, 0.6, 0.2, 1);
    `}
    ${({
      theme,
      mobileLayout,
      step,
      status,
      hasStream,
      isCover,
      aspectRatio,
    }) => {
      if (status === STATUS.INITIAL) {
        if (!hasStream) return 'display: none;';
        return `
        top: 0;
        height: 100%;
        ${theme.isRtl ? 'left' : 'right'}: 0;
        width: 100%;
        z-index: 1;
      `;
      }
      if (mobileLayout === 'DEFAULT') {
        return `
        z-index: 10;
        ${theme.isRtl ? 'left' : 'right'}: 16px;
        top: 68px;
        ${
          isCover
            ? `
        width: 112px;
        height: 168px;
        ` // 가로가 길면 가로 고정, 세로가 길면 세로 고정
            : aspectRatio > 1
              ? `
          height: ${168 / aspectRatio}px;
          width: 168px;
          `
              : `
          height: 168px;
          width: ${168 * aspectRatio}px;
        `
        }
        visibility: ${step === 1 ? 'hidden' : ' visible'};
      `;
      } else {
        return `
        z-index: 1;
        top: calc(var(--vh) * 50);
        ${theme.isRtl ? 'left' : 'right'}: 0px;
        height: calc(var(--vh) * 50);
        width: 100%;
      `;
      }
    }}
  }
  // safari <= 16에서 video에 border-radius 안 먹는 문제 해결
  will-change: transform;
`;

const HeroVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
  min-width: 100%;

  ${({ theme }) =>
    theme.isRtl &&
    `
    transform: translate(-50%, -50%) scaleX(-1);
  `}
`;

const VideoWrap = styled.div<{ hasStream: boolean }>`
  ${({ theme }) => theme.screenSize.tablet} {
    background-size: 67px 67px;
  }
  width: 100%;
  height: 100%;
  ${({ hasStream }) =>
    hasStream &&
    `
    z-index: 10;
  `}
`;
const Video = styled.canvas<{
  mobileLayout: MobileLayout;
  status: STATUS;
  isHide: boolean;
  objectFit: 'cover' | 'contain';
}>`
  object-fit: ${({ objectFit }) => objectFit};
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
  ${({ theme }) => theme.screenSize.tablet} {
    transition: border-radius 1s ease-out;
    ${({ mobileLayout, status }) =>
      mobileLayout === 'DEFAULT' &&
      status !== STATUS.INITIAL &&
      'border-radius: 10px;'}
  }
  ${({ isHide }) => isHide && 'visibility: hidden;'}
  ${({ theme, objectFit }) =>
    objectFit === 'contain' &&
    `
    background-color: ${theme.color.gray60__dkGray100};
  `}
`;

const LogoContainer = styled.div`
  padding-bottom: 82px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const TopDim = styled.div<{ hasStream: boolean; status: STATUS }>`
  ${({ theme }) => theme.screenSize.desktop} {
    display: none;
  }
  ${({ status, hasStream }) =>
    (status !== STATUS.INITIAL || !hasStream) && 'display: none;'}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 78px;
  background: ${({ theme }) =>
    `linear-gradient(180deg, rgba(${hexToRgb(theme.color.black)}, 0.3) 0%, rgba(${hexToRgb(theme.color.black)}, 0) 100%)`};
  z-index: 1;
`;

const BottomDimBase = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${`linear-gradient(180deg, rgba(${hexToRgb(theme.color.black)}, 0) 0%, rgba(${hexToRgb(theme.color.black)}, 0.4) 100%)`};
`;

const BottomDim = styled.div<{ hasStream: boolean; status: STATUS }>`
  ${({ theme }) => theme.screenSize.desktop} {
    display: none;
  }
  ${({ status, hasStream }) =>
    (status !== STATUS.INITIAL || !hasStream) && 'display: none;'}
  ${BottomDimBase}
  height: 205px;
`;

const MatchUuidBottomDim = styled.div<{
  mobileLayout: MobileLayout;
  status: STATUS;
}>`
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-bottom: 6px;
  align-items: flex-end;
  ${({ theme }) => theme.screenSize.desktop} {
    display: none;
  }
  ${({ status, mobileLayout }) =>
    (status !== STATUS.MATCHED || mobileLayout === 'HALF') && 'display: none;'}
  ${BottomDimBase}
  height: 30px;
  border-radius: 0 0 10px 10px;
`;

const MatchUuidTopDim = styled.div<{
  mobileLayout: MobileLayout;
  status: STATUS;
}>`
  z-index: 1;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: flex-end;
  align-items: center;
  ${({ theme }) => theme.screenSize.desktop} {
    display: none;
  }
  ${({ status, mobileLayout }) =>
    (status !== STATUS.MATCHED || mobileLayout === 'DEFAULT') &&
    'display: none;'}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: ${({ theme }) =>
    `linear-gradient(180deg, rgba(${hexToRgb(theme.color.black)}, 0.4) 0%, rgba(${hexToRgb(theme.color.black)}, 0) 100%)`};
`;

const MatchUuid = styled.span`
  color: rgba(${({ theme }) => hexToRgb(theme.color.gray900__dkGray970)}, 0.4);
  font-family: 'Fira Code';
  font-size: 10px;
`;

const VideoLayer = styled(VideoLayerBase)<{
  step: number;
  isHide: boolean;
  mobileLayout: MobileLayout;
}>`
  ${({ mobileLayout, step }) =>
    mobileLayout === 'DEFAULT' && step === 2 && 'border-radius: 20px;'}
  ${({ isHide }) => isHide && 'visibility: hidden;'}
  position: absolute;
  top: 0;
  ${CameraOffLayout} {
    ${({ theme }) => theme.screenSize.tablet} {
      ${({ mobileLayout, step }) =>
        mobileLayout === 'DEFAULT' && step === 2 && 'border-radius: 10px;'}
    }
  }
  ${CameraOffImage} {
    ${({ theme }) => theme.screenSize.tablet} {
      ${({ mobileLayout, step }) =>
        mobileLayout === 'DEFAULT' && step === 2 && 'border-radius: 10px;'}
    }
  }
  ${CameraOffLayoutMic} {
    ${({ theme }) => theme.screenSize.tablet} {
      ${({ mobileLayout }) =>
        mobileLayout === 'DEFAULT' &&
        `
        width: 18px;
        height: 18px;
      `}
    }
  }
  ${CameraOffLayoutMicWrap} {
    ${({ theme }) => theme.screenSize.tablet} {
      ${({ mobileLayout }) =>
        mobileLayout === 'DEFAULT' &&
        `
        border-width: 1px;
      `}
    }
  }
`;

interface IMyVideoPolygon {
  isPlayedAnimation: boolean;
}

const MyVideoPolygon: FC<IMyVideoPolygon> = ({ isPlayedAnimation }) => {
  const mobileLayout = useAtomValue(mobileLayoutAtom);
  const sourceStream = useAtomValue(sourceStreamAtom);
  const step = useAtomValue(stepAtom);
  const status = useAtomValue(statusAtom);
  const { t } = useTranslation();
  const vidRef = useAtomValue(vidRefAtom);
  const isOnCamera = useAtomValue(isOnCameraAtom);
  const profile = useAtomValue(profileAtom);
  const localAudioLevel = useAtomValue(localAudioLevelAtom);
  const device = useCssMediaDevice();
  const isDesktop = device === 'desktop';
  const matchUuid = useAtomValue(matchUuidAtom);
  const fillAudioLevelDirection = useMemo(
    () => (isDesktop || mobileLayout === 'HALF' ? 'horizontal' : 'vertical'),
    [isDesktop, mobileLayout]
  );

  const isVideoObjectFitCover = useAtomValue(isVideoObjectFitCoverAtom);
  const myVideoAspectRatio = useAtomValue(myVideoAspectRatioAtom);

  const isVideoObjectFitCoverStyle = useMemo(() => {
    if (isVideoObjectFitCover) return 'cover';
    // 모바일 step 2에서는 원본 비율 유지한 채 긴쪽을 168px로 맞추고 렌더링
    if (device !== 'desktop' && step === 2 && mobileLayout === 'DEFAULT')
      return 'cover';
    return 'contain';
  }, [device, isVideoObjectFitCover, mobileLayout, step]);

  return (
    <Container
      step={step}
      status={status}
      isPlayedAnimation={isPlayedAnimation}
      mobileLayout={mobileLayout}
      hasStream={!!sourceStream}
      isCover={isVideoObjectFitCover}
      aspectRatio={myVideoAspectRatio}
    >
      {!sourceStream && (
        <>
          <HeroVideo
            autoPlay
            muted
            loop
            playsInline
            poster='/images/match/Leftside_first_frame.png'
          >
            <source src='/videos/main/Leftside_Web.mp4' type='video/mp4' />
            {t('VIDEO_NOT_SUPPORTED')}
          </HeroVideo>
          <LogoContainer>
            <TextLogo
              width={300}
              height={78}
              color={color.dkGray200}
              arWidth={134}
              arHeight={100}
              ptWidth={379}
              ptHeight={78}
            />
            <MatchCounter />
          </LogoContainer>
        </>
      )}
      <TopDim hasStream={!!sourceStream} status={status} />
      <VideoWrap hasStream={!!sourceStream}>
        <Video
          ref={vidRef}
          mobileLayout={mobileLayout}
          status={status}
          isHide={!isOnCamera}
          objectFit={isVideoObjectFitCoverStyle}
        />
        <MatchUuidBottomDim status={status} mobileLayout={mobileLayout}>
          <MatchUuid data-testid='match-uuid'>{matchUuid}</MatchUuid>
        </MatchUuidBottomDim>
        <MatchUuidTopDim status={status} mobileLayout={mobileLayout}>
          <MatchUuid>{matchUuid}</MatchUuid>
        </MatchUuidTopDim>
        {sourceStream && profile && (
          <VideoLayer
            isLoading={false}
            audioLevel={localAudioLevel}
            cameraEnabled={step === 1}
            src={
              profile?.profileImageInfos?.[0]?.profileImageUrl ||
              defaultProfileImage
            }
            step={step}
            isHide={isOnCamera}
            dimmed={true}
            mobileLayout={mobileLayout}
            fillAudioLevelDirection={fillAudioLevelDirection}
          />
        )}
      </VideoWrap>
      <BottomDim hasStream={!!sourceStream} status={status} />
      {!isDesktop && status === STATUS.MATCHED && <Watermark />}
    </Container>
  );
};

export default MyVideoPolygon;
