import { useEffect, useRef } from 'react';

const useOutsideClick = <T extends HTMLElement>(callback: () => void) => {
  const ref = useRef<T | null>(null);
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (
      e.target instanceof HTMLElement &&
      e.target.isConnected &&
      ref.current &&
      !ref.current.contains(e.target as HTMLElement) &&
      !(e.target as HTMLElement).className?.includes?.('ModalBack')
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
  return ref;
};

export default useOutsideClick;
