import React from 'react';

import styled from '@emotion/styled';

import AnimatedText from 'src/components/AnimatedText';
import Gap from 'src/components/Gap';
import { MobileLayout, PeerProfile } from 'src/types/Match';

import * as Styles from './styles';
import {
  Country,
  CountryFlag,
  CountryText,
  Image as ImageBase,
  Medal,
  Medals,
  Name,
} from './styles';
import AzarBadgeBase from 'src/components/AzarBadge';
import { AzarBadgeStatus } from 'src/stores/azarBadge/types';

const Image = styled(ImageBase)<{ mobileLayout: MobileLayout }>`
  display: inline-flex;
  width: auto;
  height: auto;
  border-radius: 330px;
  max-width: 330px;
  max-height: 330px;
  ${({ theme }) => theme.screenSize.tablet} {
    max-width: 200px;
    max-height: 200px;
  }
  width: calc(var(--100vh) - 440px);
  height: calc(var(--100vh) - 440px);
  ${({ theme }) => theme.screenSize.tablet} {
    ${({ mobileLayout }) =>
      mobileLayout === 'HALF' &&
      `
      width: 140px;
      height: 140px;
      border-radius: 140px;
    `};
  }
`;

const AzarBadge = styled(AzarBadgeBase)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

const Root = styled(AnimatedText)`
  position: absolute;
  text-align: center;
  padding: 0 40px;
`;
const Inner = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
`;
const Row = styled(Styles.Row)`
  width: 100%;
  margin-bottom: 9px;
`;

const Info = styled(Styles.Info)<{ mobileLayout: MobileLayout }>`
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 24px;
  ${({ theme }) => theme.screenSize.tablet} {
    ${({ mobileLayout }) =>
      mobileLayout === 'DEFAULT'
        ? `
      margin-top: 36px;
    `
        : `
      margin-top: 10px;
    `}
  }
`;
interface Props {
  peerProfile: PeerProfile;
  mobileLayout: MobileLayout;
}
const Starting: React.FC<Props> = ({ peerProfile, mobileLayout }) => (
  <Root isFade>
    <Inner data-testid='Match-PeerProfile-Starting'>
      <Image
        src={peerProfile.profileImageUrl}
        alt='profile image'
        mobileLayout={mobileLayout}
      />
      <Info mobileLayout={mobileLayout}>
        <Row>
          <Name>{peerProfile.simpleName}</Name>
          {peerProfile.azarBadge &&
            peerProfile.azarBadge.state !== AzarBadgeStatus.NOT_ACHIEVED && (
              <>
                <Gap width={6} mobileWidth={4} />
                <AzarBadge
                  azarBadge={peerProfile.azarBadge}
                  showModalOnClick={false}
                  isMine={false}
                />
              </>
            )}
          {!!peerProfile.medalIcons?.length && (
            <>
              <Gap width={6} />
              <Medals>
                {peerProfile.medalIcons.map((value) => (
                  <Medal
                    key={`PeerProfile-Medal-${value}`}
                    src={`/images/icons/icon${value}.svg`}
                  />
                ))}
              </Medals>
              <Gap width={6} />
            </>
          )}
        </Row>
        <Country>
          <CountryFlag
            src={`/images/flags/icFlag${peerProfile.location?.countryCode || peerProfile.country}.png`}
          />
          <Gap width={4} />
          <CountryText>{peerProfile.location?.country}</CountryText>
        </Country>
      </Info>
    </Inner>
  </Root>
);

export default Starting;
