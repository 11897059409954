import { useEffect } from 'react';

import { useSetAtom } from 'jotai';
import { fromEvent, throttleTime } from 'rxjs';

import { setMatchHeaderAtom } from 'src/stores/header/atoms';
import { screenWidth } from 'src/styles/screenSize';

const useMatchHeader = () => {
  const setMatchHeader = useSetAtom(setMatchHeaderAtom);

  useEffect(() => {
    const subscription = fromEvent(window, 'resize')
      .pipe(throttleTime(100))
      .subscribe(() => {
        setMatchHeader(window.innerWidth > screenWidth.tablet);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [setMatchHeader]);
};

export default useMatchHeader;
