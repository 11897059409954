import React from 'react';

import styled from '@emotion/styled';

import Gap from 'src/components/Gap';
import { PeerProfile } from 'src/types/Match';
import { hexToRgb } from 'src/utils/common';

import { Country, Image as ImageBase, Info, Name as NameBase } from './styles';
import AzarBadgeBase from 'src/components/AzarBadge';
import { AzarBadgeStatus } from 'src/stores/azarBadge/types';

const CountryFlag = styled.img`
  width: 14px;
  height: 10px;
`;

const Image = styled(ImageBase)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 44px;
    height: 44px;
    border-radius: 22px;
  }
`;

const Root = styled.div`
  display: flex;
  z-index: 1;
`;
const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;
const Name = styled(NameBase)`
  max-width: 100px;
  text-shadow: 0px 1px 3px
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 0.4);

  color: ${({ theme }) => theme.color.white};
  font-size: 18px;
  line-height: 25px;
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 14px;
    line-height: 17px;
  }
`;

const AzarBadge = styled(AzarBadgeBase)`
  width: 14px;
  height: 14px;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Props {
  peerProfile: PeerProfile;
}
const Connected: React.FC<Props> = ({ peerProfile }) => (
  <Root>
    <Inner data-testid='Match-PeerProfile-Connected'>
      <Image src={peerProfile.profileImageUrl} alt='Profile Image' />
      <Gap width={8} />
      <Info>
        <InfoRow>
          <Name>{peerProfile.simpleName}</Name>
          {peerProfile.azarBadge &&
            peerProfile.azarBadge.state !== AzarBadgeStatus.NOT_ACHIEVED && (
              <>
                <Gap width={4} />
                <AzarBadge
                  azarBadge={peerProfile.azarBadge}
                  showModalOnClick={false}
                  isMine={false}
                />
              </>
            )}
        </InfoRow>
        <InfoRow>
          <Country>
            <CountryFlag
              src={`/images/flags/icFlag${peerProfile.location?.countryCode || peerProfile.country}.png`}
              alt='country'
            />
            <Gap width={4} />
            {peerProfile.location?.country}
            <Gap width={6} />
          </Country>
        </InfoRow>
      </Info>
    </Inner>
  </Root>
);

export default Connected;
