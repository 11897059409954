import styled from '@emotion/styled';

import ProfileImage from 'src/components/ProfileImage';
import color from 'src/styles/color';
import { hexToRgb } from 'src/utils/common';

export const Image = styled(ProfileImage)`
  width: 280px;
  height: 280px;
  border-radius: 280px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 201px;
    height: 201px;
    border-radius: 201px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Country = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-weight: 700;
  font-size: 10px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
`;

export const CountryFlag = styled.img`
  width: 22px;
  height: 16px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 14px;
    height: 10px;
  }
`;

export const CountryText = styled.span`
  font-weight: 400;
  white-space: nowrap;
  opacity: 0.9;
  color: ${({ theme }) => theme.color.white};
  font-size: 14px;
  line-height: 19px;
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  line-height: 41px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => theme.screenSize.tablet} {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Medals = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
  color: ${({ theme }) => theme.color.green500};
`;

export const MedalDivider = styled.div`
  width: 1px;
  height: 6px;
  background-color: ${({ theme }) => theme.color.gray400__dkGray300};
`;

export const Medal = styled.img`
  width: 18px;
  height: 18px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 15px;
    height: 15px;
  }
`;

const rateColorMap = [
  'rgba(0, 141, 235, 0.5)',
  `rgba(${hexToRgb(color.pink500)}, 0.5)`,
  'rgba(255, 208, 51, 0.5)',
  `rgba(${hexToRgb(color.green500)}, 0.5)`,
  'rgba(0, 141, 235, 0.5)',
];
export const Rate = styled.div<{ range: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ range }) => rateColorMap[range] || rateColorMap[0]};
  ${({ theme }) => theme.screenSize.tablet} {
    padding: 2px 4px;
  }
`;

export const RateIcon = styled.img`
  ${({ theme }) => (theme.isRtl ? 'margin-left' : 'margin-right')}: 2px;
  width: 20px;
  height: 20px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 16px;
    height: 16px;
  }
`;

export const RateText = styled.div`
  color: ${({ theme }) => theme.color.white};
  font-size: 14px;
  line-height: 19px;
  ${({ theme }) => theme.screenSize.tablet} {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
  }
`;
