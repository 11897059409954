import { FC, useEffect } from 'react';

import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import CoachMarks from 'src/components/Match/Matching/CoachMarks';
import PermissionErrorFullModal from 'src/components/Match/Matching/PermissionErrorFullModal';
import {
  RedDot,
  ToolButton,
  ToolButtonIcon,
} from 'src/components/Match/Matching/Toolbar/styles';
import useCallbackWithCheckLogin from 'src/hooks/useCallbackWithCheckLogin';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import useIsOpenModal from 'src/hooks/useIsOpenModal';
import {
  activeDecoEffectAtom,
  activeEffectGroupIdAtom,
  handleUnsupportedDecoAccessAtom,
  isDecoSupportedAtom,
  isShowDecoCoachMarkAtom,
  isShowDecoRedDotAtom,
  isShowToolbarAtom,
} from 'src/stores/deco/atom';
import { headerAtom } from 'src/stores/header/atoms';
import {
  eventMatchPageAtom,
  grantVideoAtom,
  statusAtom,
} from 'src/stores/match/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { STATUS } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import { EffectsModal } from 'src/components/Effects';

const Wrap = styled.div`
  position: relative;
`;

const DecoFilterButton: FC = () => {
  const showModal = useSetAtom(showModalAtom);
  const isOpenDecoModal = useIsOpenModal(ModalType.DECO_STUDIO);
  const setIsShowToolbar = useSetAtom(isShowToolbarAtom);
  const setIsShowHeader = useSetAtom(headerAtom);
  const media = useCssMediaDevice();
  const isShowRedDot = useAtomValue(isShowDecoRedDotAtom);
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const status = useAtomValue(statusAtom);
  const eventMatchPage = useAtomValue(eventMatchPageAtom);
  const handleUnsupportedDecoAccess = useSetAtom(
    handleUnsupportedDecoAccessAtom
  );
  const pushEvent = useEvent();
  const { t } = useTranslation();

  const activeEffect = useAtomValue(activeDecoEffectAtom);
  const activeGroupId = useAtomValue(activeEffectGroupIdAtom);

  const [isShow, setIsShow] = useAtom(isShowDecoCoachMarkAtom);

  useEffect(() => {
    if (media !== 'desktop') {
      setIsShowToolbar(!isOpenDecoModal && status === STATUS.INITIAL);
      setIsShowHeader(!isOpenDecoModal && status === STATUS.INITIAL);
    } else {
      setIsShowToolbar(true);
    }
  }, [isOpenDecoModal, setIsShowToolbar, media, setIsShowHeader, status]);

  const grantVideo = useSetAtom(grantVideoAtom);
  const showDecoModal = () => {
    showModal({
      key: ModalType.DECO_STUDIO,
      component: () => <EffectsModal />,
    });
  };
  const onClick = useCallbackWithCheckLogin(async () => {
    if (!isDecoSupported) {
      handleUnsupportedDecoAccess();
      return;
    }

    await grantVideo({
      onSuccess: showDecoModal,
      errorModal: PermissionErrorFullModal,
    });
    pushEvent({
      eventType: EVENT_TYPE.DECO,
      eventName: EVENT_NAME.OPEN_DECO_STUDIO,
      eventParams: {
        action_category: 'open',
        tab: 'mirror',
        page: eventMatchPage,
        target: 'deco',
        effectId: activeEffect?.effectId,
        groupId: activeGroupId,
      },
    });
  });

  return (
    // 사용자 유도 관련 UI(eg. 레드닷, 코치마크)는 데코 이용 불가시 미노출
    <Wrap>
      <ToolButton isAvailable={isDecoSupported} onClick={onClick}>
        {isShowRedDot && isDecoSupported && <RedDot />}
        <ToolButtonIcon
          alt='Deco filter'
          src='/images/icons/icEffectFill.svg'
        />
      </ToolButton>
      <CoachMarks
        image='/images/deco/coachMark.webp'
        description={t('deco_studio_coach_mark')}
        isShow={isShow && !!isDecoSupported}
        setIsShow={setIsShow}
      />
    </Wrap>
  );
};

export default DecoFilterButton;
