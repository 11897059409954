import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import {
  getDecoEffectListAtom,
  handleDecoUnsupportAtom,
  isDecoSupportedAtom,
  loadEffectRendererPipeAtom,
} from 'src/stores/deco/atom';
import { DecoUnsupportReason } from 'src/types/Deco';
import { isWebGL2Supported } from 'src/utils/media/compatibility';
import useLogin from 'src/hooks/useLogin';

const useInitDecoEffect = () => {
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const loadEffectRendererPipe = useSetAtom(loadEffectRendererPipeAtom);
  const handleDecoUnsupport = useSetAtom(handleDecoUnsupportAtom);
  const getDecoEffectList = useSetAtom(getDecoEffectListAtom);
  const { isLogin } = useLogin();

  // 이펙트 관련 데이터 서버로부터 fetch
  useEffect(() => {
    if (isLogin) {
      getDecoEffectList();
    }
  }, [getDecoEffectList, isLogin]);

  // 이펙트 라이브러리 initialize
  useEffect(() => {
    if (!isDecoSupported) return;

    if (!isWebGL2Supported()) {
      handleDecoUnsupport(DecoUnsupportReason.WEBGL2_NOT_SUPPORTED);
      return;
    }
    loadEffectRendererPipe();
  }, [handleDecoUnsupport, isDecoSupported, loadEffectRendererPipe]);
};

export default useInitDecoEffect;
