import { FC, useEffect } from 'react';

import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';

import BottomTabBar from 'src/components/BottomTabBar';
import FooterBase from 'src/components/Footer';
import Header from 'src/components/Header';
import Matching from 'src/components/Match/Matching';
import { statusAtom, stepAtom } from 'src/stores/match/atoms';
import { STATUS } from 'src/types/Match';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { isDecoSupportedAtom } from 'src/stores/deco/atom';

const Container = styled.div<{ isShowBottomTabBar: boolean }>`
  display: flex;
  height: var(--100vh);
  ${({ isShowBottomTabBar, theme }) =>
    isShowBottomTabBar &&
    `${theme.screenSize.tablet} {
    height:  calc(var(--100vh) - 56px);
  }`}
`;
const Wrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Footer = styled(FooterBase)`
  ${({ theme }) => theme.screenSize.tablet} {
    margin-bottom: 56px;
  }
`;
export const getStaticProps = () => ({
  props: {
    canonical: true,
    metas: [
      {
        property: 'og:title',
        content: 'OG_TITLE',
        key: 'og:title',
      },
      {
        property: 'og:description',
        content: 'OG_DESC',
        key: 'og:description',
      },
      {
        name: 'description',
        content: 'OG_DESC',
        key: 'description',
      },
      {
        property: 'og:image',
        content: '/images/og/azarOgImage.png',
        key: 'og:image',
      },
    ],
  },
});

const Match: FC = () => {
  const step = useAtomValue(stepAtom);
  const status = useAtomValue(statusAtom);
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const pushEvent = useEvent();

  useEffect(() => {
    if (status === STATUS.INITIAL && isDecoSupported !== undefined) {
      pushEvent({
        eventType: EVENT_TYPE.DECO,
        eventName: EVENT_NAME.MIRROR_REPORT_EFFECT_AVAILABLE,
        eventParams: {
          action_category: 'report',
          tab: 'mirror',
          page: 'main',
          target: 'effect_available',
          effect_available: isDecoSupported,
        },
      });
    }
  }, [status, pushEvent, isDecoSupported]);

  return (
    <Wrap>
      <Container isShowBottomTabBar={step === 1}>
        <Header />
        <Matching />
      </Container>
      <Footer />
      {step === 1 && <BottomTabBar />}
    </Wrap>
  );
};

export default Match;
