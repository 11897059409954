import styled from '@emotion/styled';

export const NickNameWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 100%;
  cursor: pointer;
`;

export const TextWrap = styled.span`
  width: 100%;
  word-break: break-all;
  font-family: 'Noto Sans';
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
`;
