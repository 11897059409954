import { FC } from 'react';

import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import DesktopComponentBase from 'src/components/DesktopComponent';
import { activeDecoEffectAtom, decoListAtom } from 'src/stores/deco/atom';
import { DecoEffect, decoEffectGroupNameCrowdinKey } from 'src/types/Deco';

import EffectButton from './EffectButton';
import RemoveEffectButton from './RemoveEffectButton';

const DesktopComponent = styled(DesktopComponentBase)`
  max-height: 440px;
  flex: 1;
  flex-wrap: nowrap;
  padding-bottom: 20px;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;
const SectionWrap = styled.div``;
const SectionTitleWrap = styled.div`
  padding: 16px 0px;
`;
const SectionTitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray600__dkGray500};
`;
const FilterList = styled.div`
  padding-bottom: 12px;
  flex-direction: row;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-grow: 1;
`;

interface Props {
  handleClickEffect: (effect: DecoEffect) => void;
}

const DesktopEffects: FC<Props> = ({ handleClickEffect }) => {
  const decoList = useAtomValue(decoListAtom);
  const { t } = useTranslation();
  const activeEffect = useAtomValue(activeDecoEffectAtom);

  return (
    <DesktopComponent>
      <RemoveEffectButton handleClickEffect={handleClickEffect} />
      {decoList.map((effectGroup) => (
        <SectionWrap key={effectGroup.groupId}>
          <SectionTitleWrap>
            <SectionTitle>
              {t(decoEffectGroupNameCrowdinKey[effectGroup.groupId])}
            </SectionTitle>
          </SectionTitleWrap>
          <FilterList>
            {effectGroup.effects.map((effect) => (
              <EffectButton
                key={effect.effectId}
                isNew={effect.newBadgeEnabled}
                effectId={effect.effectId}
                thumbnail={effect.thumbnailImageUrl}
                active={activeEffect?.effectId === effect.effectId}
                onClick={() => {
                  handleClickEffect(effect);
                }}
              />
            ))}
          </FilterList>
        </SectionWrap>
      ))}
    </DesktopComponent>
  );
};

export default DesktopEffects;
