import React, { useCallback, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import Gap from 'src/components/Gap';
import {
  ModalHeaderDescriptionRow,
  ModalProps,
  SaveButton,
} from 'src/components/Match/Preference/Modal';
import ModalLayoutBase, {
  MobileModalType,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTitle,
} from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import useGemAmount from 'src/hooks/useGemAmount';
import useUserData from 'src/hooks/useUserData';
import { userInventoryAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { showGemLackModalAtom } from 'src/stores/shop/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { MatchFilterOption } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import { GENDER } from 'src/types/register';
import { hexToRgb } from 'src/utils/common';

import GenderIconBase from './Icon';
import useGenderPreference from './useGenderPreference';
import { mouseHoverCss } from 'src/utils/styled';
import { getRequiredGemFromMatchFilterAtom } from 'src/stores/match/atoms';

const ModalLayout = styled(ModalLayoutBase)`
  width: 400px;
`;

const Row = styled.div`
  display: grid;
  height: min-content;
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${({ theme }) => theme.color.gray60__dkGray100};
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Wrap = styled.label<{ active: boolean }>`
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  box-sizing: border-box;
  border-radius: 16px;
  border-width: 2px;
  border-style: solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) =>
    mouseHoverCss(`
      background-color: rgba(
      ${hexToRgb(theme.color.green500)},
      0.2
    );
  `)}
  ${({ theme, active }) =>
    active
      ? `
    background-color: rgba(${hexToRgb(theme.color.green500)}, 0.2);
    border-color: ${theme.color.green500};
    color: ${theme.color.gray900__dkGray970};
  `
      : `
    background-color: ${theme.color.gray100__dkGray150};
    border-color: transparent;
    color: ${theme.color.gray400__dkGray300};
  `}
  ${({ theme }) => theme.screenSize.tablet} {
    height: 130px;
  }
`;

const ImgBase = css`
  width: 64px;
  height: 64px;
`;

const ImgPicture = styled.picture`
  ${ImgBase}
  margin-bottom: 18px;
  ${({ theme }) => theme.screenSize.tablet} {
    margin-bottom: 12px;
  }
`;
const ImgSource = styled.source`
  ${ImgBase};
`;

const Img = styled.img`
  ${ImgBase};
`;
const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

const GenderFilterCouponIcon = styled(GenderIconBase)`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
`;

const GenderPreferenceModal: React.FC<ModalProps> = ({
  onSave,
  onClose,
  landFrom,
}) => {
  const { t } = useTranslation();
  const gemAmount = useGemAmount();
  const inventoryItems = useAtomValue(userInventoryAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const { userProfile } = useUserData() || {};
  const pushEvent = useEvent();

  const getRequiredGemFromMatchFilter = useSetAtom(
    getRequiredGemFromMatchFilterAtom
  );

  const {
    matchFilterSelections,
    setMatchFilterSelections,
    genderMatchFilter,
    genderMatchFilterOption,
    genderMatchFilterSelection,
  } = useGenderPreference();

  const [nextGenderMatchFilterOption, setNextGenderMatchFilterOption] =
    useState(genderMatchFilterOption);

  useEffect(() => {
    if (genderMatchFilterOption) {
      setNextGenderMatchFilterOption(genderMatchFilterOption);
    }
  }, [genderMatchFilterOption]);

  const showGemLackModal = useSetAtom(showGemLackModalAtom);

  const getNextMatchFilterSelections = useCallback(
    (matchFilterOption: MatchFilterOption) => {
      const { key: optionKey, gemCost: optionGemCost } = matchFilterOption;
      return matchFilterSelections.map((matchFilterSelection) => {
        if (matchFilterSelection !== genderMatchFilterSelection) {
          return matchFilterSelection;
        }
        return {
          ...matchFilterSelection,
          optionKey,
          optionGemCost,
        };
      });
    },
    [genderMatchFilterSelection, matchFilterSelections]
  );

  const handleChangeGender = (matchFilterOption: MatchFilterOption) => {
    const eventName =
      EVENT_NAME[`${landFrom}__FILTER_${matchFilterOption.key as 'DEFAULT'}`];
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      ...(eventName
        ? { eventName }
        : {
            eventName: EVENT_NAME[`${landFrom}__FILTER_DEFAULT`],
            eventParams: { value: matchFilterOption.key },
          }),
    });
    const { gemCost: optionGemCost } = matchFilterOption;
    if (optionGemCost === 0)
      return setNextGenderMatchFilterOption(matchFilterOption);

    const targetMatchFilterSelections =
      getNextMatchFilterSelections(matchFilterOption);
    const requiredGems = getRequiredGemFromMatchFilter(
      targetMatchFilterSelections
    );
    const lackingAmount = requiredGems - (gemAmount || 0);
    if (lackingAmount <= 0) {
      return setNextGenderMatchFilterOption(matchFilterOption);
    }
    showGemLackModal({
      lackingAmount,
      description: t('NOT_ENOUGH_GEM_POPUP_GENDER_DESCRIPTION', {
        gems: matchFilterOption?.gemCost,
      }),
    });
  };

  const handleClose = () => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME[`${landFrom}__GENDERFILTER_CLOSE`],
    });
    onClose?.();
  };

  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME[`${landFrom}__OPEN_GENDERFILTER`],
    });
  }, [pushEvent, landFrom]);

  if (
    !genderMatchFilter ||
    !genderMatchFilterSelection ||
    gemAmount === undefined ||
    !inventoryItems ||
    !userProfile
  ) {
    return null;
  }
  const genderFilterItemQuantity = inventoryItems.reduce(
    (sum, { categoryId, quantity }) => {
      if (categoryId !== genderMatchFilter.consumableCategoryId) {
        return sum;
      }
      return sum + quantity;
    },
    0
  );

  const save = () => {
    if (!nextGenderMatchFilterOption) {
      return;
    }
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME[`${landFrom}__GENDERFILTER_GOMATCH`],
    });

    const nextMatchFilterSelections = getNextMatchFilterSelections(
      nextGenderMatchFilterOption
    );
    setMatchFilterSelections(nextMatchFilterSelections);

    closeModal(ModalType.MATCH_SETTING);
  };

  const handleClickSave = () => {
    save();
    onClose?.();
  };
  const handleClickStart = () => {
    save();
    onSave();
  };

  return (
    <ModalLayout
      mobileType={MobileModalType.BOTTOM_SHEET}
      isDim
      onClose={handleClose}
      data-testid='Match-GenderPreferenceModal'
    >
      <ModalHeader>
        <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET}>
          {t('MATCH_WITH')}
        </ModalHeaderTitle>
        <ModalHeaderDescriptionRow>
          {!nextGenderMatchFilterOption?.gemCost ? (
            t('VIDEOCHAT_GENDER_DESC')
          ) : (
            <>
              {!!genderFilterItemQuantity && (
                <>
                  <GenderFilterCouponIcon />
                  <Gap width={8} />
                </>
              )}
              <Trans
                i18nKey={
                  genderFilterItemQuantity
                    ? 'FILTER_GENDER_GUIDE_ITEM'
                    : 'FILTER_GENDER_GUIDE_GEM'
                }
                values={{
                  itemCount:
                    genderFilterItemQuantity ||
                    nextGenderMatchFilterOption?.gemCost,
                }}
              />
            </>
          )}
        </ModalHeaderDescriptionRow>
      </ModalHeader>
      <ModalContent>
        <Row>
          {genderMatchFilter.filterOptions.map((filterOption) => {
            const gender = filterOption.key;
            const key = `GenderPreferenceModal-${gender}`;
            const active = gender === nextGenderMatchFilterOption?.key;
            return (
              <Wrap key={key} htmlFor={key} active={active}>
                <ImgPicture>
                  {gender !== 'ALL' &&
                    gender !== 'BOTH' &&
                    gender !== userProfile.gender &&
                    userProfile.gender !== GENDER.NONBINARY && (
                      <ImgSource
                        srcSet={`/images/match/${key}.webp`}
                        type='image/webp'
                      />
                    )}
                  <ImgSource
                    srcSet={`/images/match/${key}.png`}
                    type='image/png'
                  />
                  <Img
                    src={`/images/match/${key}.png`}
                    alt={`Gender prefer ${key}`}
                  />
                </ImgPicture>
                <Title>{filterOption.displayName}</Title>
                <input
                  id={key}
                  type='radio'
                  value={gender}
                  name={`${key}-radio`}
                  hidden
                  checked={active}
                  onChange={() => handleChangeGender(filterOption)}
                />
              </Wrap>
            );
          })}
        </Row>
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={handleClickStart}
          id='VIDEOCHAT_MAIN_BUTTON'
          data-testid='Match-GenderPreferenceModal-start'
        >
          {t('VIDEOCHAT_MAIN_BUTTON')}
        </Button>
        <SaveButton
          id='FILTER_GENDER_SAVE'
          onClick={handleClickSave}
          variant='text'
        >
          {t('SAVE')}
        </SaveButton>
      </ModalFooter>
    </ModalLayout>
  );
};

export default GenderPreferenceModal;
