import styled from '@emotion/styled';
import { IconBase } from 'src/styles/icons';

const IcPreset = ['ALL', 'BOTH', 'MALE', 'FEMALE'];

const GenderIconBase = styled.div<{ gender?: string }>`
  ${IconBase}
  background-image: url("/images/match/ic${({ gender = '' }) =>
    IcPreset.includes(gender) ? gender : 'ALL'}.png");
`;

export default GenderIconBase;
