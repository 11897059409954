import React, { FC } from 'react';

import Gap from 'src/components/Gap';

import { ChatContainer, Icon, Profile, RootStyle } from './style';

interface ChatProps {
  iconSource?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

const ChatLayout: FC<ChatProps> = ({
  iconSource,
  children,
  className,
  onClick,
}) => (
  <RootStyle
    onClick={onClick}
    hasClick={!!onClick}
    className={className}
    data-testid='chat-layout'
  >
    <Profile>
      <Icon src={iconSource} alt={`ic${iconSource}`} />
    </Profile>
    <Gap width={4} />
    <ChatContainer>{children}</ChatContainer>
  </RootStyle>
);

export { ChatLayout };
export type { ChatProps };
