import { useEffect } from 'react';

const useVisibilityChange = (listener: () => void) => {
  useEffect(() => {
    document.addEventListener('visibilitychange', listener);

    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, [listener]);
};

export default useVisibilityChange;
