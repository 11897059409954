import { useAtom, useAtomValue } from 'jotai';

import {
  matchFiltersAtom,
  matchFilterSelectionsAtom,
} from 'src/stores/match/atoms';

const regionFilterSelector = ({ filterId }: { filterId: string }) =>
  filterId === 'REGION_CHOICE';
const countryFilterSelector = ({ filterId }: { filterId: string }) =>
  filterId === 'COUNTRY_GUARANTEE';

const useLocationPreference = () => {
  const matchFilters = useAtomValue(matchFiltersAtom);
  const [matchFilterSelections, setMatchFilterSelections] = useAtom(
    matchFilterSelectionsAtom
  );
  const regionMatchFilter = matchFilters?.find(regionFilterSelector);
  const regionMatchFilterSelection =
    matchFilterSelections.find(regionFilterSelector);
  const regionMatchFilterOption = regionMatchFilter?.filterOptions.find(
    ({ key }) => key === regionMatchFilterSelection?.optionKey
  );
  const countryMatchFilter = matchFilters?.find(countryFilterSelector);
  const countryMatchFilterSelection = matchFilterSelections.find(
    countryFilterSelector
  );
  const countryMatchFilterOption = countryMatchFilter?.filterOptions.find(
    ({ key }) => key === countryMatchFilterSelection?.optionKey
  );

  return {
    matchFilterSelections,
    setMatchFilterSelections,
    regionMatchFilter,
    regionMatchFilterSelection,
    regionMatchFilterOption,
    countryMatchFilter,
    countryMatchFilterSelection,
    countryMatchFilterOption,
  };
};

export default useLocationPreference;
