import React, { HtmlHTMLAttributes } from 'react';

import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';

import useEvent from 'src/hooks/useEvent';
import {
  blockPeerMediaAtom,
  matchConfigAtom,
  matchInfoAtom,
  peerCameraEnabledAtom,
  peerVidRefAtom,
  peerVideoAtom,
} from 'src/stores/match/atoms';
import { showModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import { getCanvasCaptureBlob } from 'src/utils/media/blob';
import MatchReportModal from './Modal';

const Base = styled.button`
  background-color: transparent;
  background-image: url('/images/match/btnReport.svg');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  padding: 0;
  z-index: 1;

  width: 32px;
  height: 32px;
`;

const ReportButton: React.FC<HtmlHTMLAttributes<HTMLButtonElement>> = (
  props
) => {
  const peerVidRef = useAtomValue(peerVidRefAtom);
  const showModal = useSetAtom(showModalAtom);
  const matchInfo = useAtomValue(matchInfoAtom);
  const matchConfig = useAtomValue(matchConfigAtom);
  const isPeerCameraEnabled = useAtomValue(peerCameraEnabledAtom);
  const pushEvent = useEvent();
  const blockPeerMedia = useSetAtom(blockPeerMediaAtom);
  const peerVideoStreamPipeManager = useAtomValue(peerVideoAtom);

  const handleClickButton = async () => {
    if (!peerVidRef.current || !matchInfo) {
      return;
    }

    let file: Blob | null = null;

    // 매치 시작 직후에는 상대 stream pipe 세팅이 완료되지 않았을 수 있음 (앤트맨 셋업 딜레이 등 고려)
    if (isPeerCameraEnabled && peerVideoStreamPipeManager) {
      /**
       * 캔버스에 최초로 그린 직후에는 결과물이 잘 캡쳐되지 않는 경우 존재
       * 약간의 딜레이 후 그려진 내용 추출
       */
      await peerVideoStreamPipeManager.firstFrameRenderedPromise.promise;
      file = getCanvasCaptureBlob(peerVidRef.current);
    }

    const eventParams = {
      action_category: 'click',
      tab: 'mirror',
      page: 'match',
      target: 'icon',
      target_id: 'user_request_id',
      target_detail: matchConfig?.requestId,
    };

    pushEvent({
      eventName: EVENT_NAME.REPORT_BLOCK_CLICK,
      eventType: EVENT_TYPE.REPORT,
      eventParams,
    });

    showModal({
      key: ModalType.REPORT,
      component: () => (
        <MatchReportModal
          matchInfo={matchInfo}
          peerVideoCaptureBlob={file}
          eventParams={eventParams}
        />
      ),
    });
    blockPeerMedia(true);
  };

  return <Base onClick={handleClickButton} {...props} />;
};

export default ReportButton;
