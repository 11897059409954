import { useEffect } from 'react';

import { useSetAtom } from 'jotai';

import PermissionErrorFullModal from 'src/components/Match/Matching/PermissionErrorFullModal';
import useLogin from 'src/hooks/useLogin';
import { clearSourceStreamAtom, grantVideoAtom } from 'src/stores/match/atoms';

const useGrantVideo = () => {
  const grantVideo = useSetAtom(grantVideoAtom);
  const clearSourceStream = useSetAtom(clearSourceStreamAtom);
  const { isLogin } = useLogin();

  useEffect(() => {
    if (!isLogin) {
      return clearSourceStream();
    }
    grantVideo({
      errorModal: PermissionErrorFullModal,
    });
    return () => {
      clearSourceStream();
    };
  }, [clearSourceStream, grantVideo, isLogin]);
};

export default useGrantVideo;
