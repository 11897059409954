import { FC, useRef } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const Input = styled.textarea`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  gap: 4px;
  box-sizing: border-box;
  border-radius: 100px;

  width: 100%;
  max-height: 100%;
  min-height: 48px;
  height: 48px;
  color: ${({ theme }) => theme.color.white};
  font-size: 15px;
  overflow: visible;
  border: 1px solid transparent;

  background-color: ${({ theme }) => theme.color.gray100__dkGray150};
  ${({ theme }) => theme.screenSize.mobile} {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  resize: none;
  ::-webkit-scrollbar {
    display: none;
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.color.green500};
    outline: none;
    outline-width: 0;
  }
  :active {
    border: 1px solid ${({ theme }) => theme.color.green500};
    outline-width: 0;
  }
  ::placeholder {
    color: ${({ theme }) => theme.color.gray900__dkGray970};
    opacity: 0.7;
  }
`;

interface ChatInputDesktopProps {
  value: string;
  onChangeText: (value: string) => void;
  onClickSubmit: () => void;
}

const ChatInputDesktop: FC<ChatInputDesktopProps> = ({
  onChangeText,
  value,
  onClickSubmit,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    e
  ) => {
    if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
      e.preventDefault();
      if (!e.shiftKey) {
        onClickSubmit();
        if (ref.current) {
          ref.current.style.height = '48px';
        }
      }
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const text = e.target.value.replace(/\n/g, '').slice(0, 99);
    onChangeText(text);
    if (ref.current) {
      ref.current.style.height = 'auto'; // 높이 초기화
      ref.current.style.height = `${ref.current.scrollHeight}px`; // 내용에 맞는 높이 설정
    }
  };
  return (
    <Input
      ref={ref}
      data-testid='ChatInputDesktop-textarea'
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      rows={1}
      value={value}
      placeholder={t('ROOM__CHAT_INPUT_HINT')}
    />
  );
};

export default ChatInputDesktop;
export type { ChatInputDesktopProps };
