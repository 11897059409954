import { FC, useCallback, useMemo } from 'react';

import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';

import {
  ToolButton as ToolButtonBase,
  ToolButtonIcon,
} from 'src/components/Match/Matching/Toolbar/styles';
import useEnableRemoteConfig from 'src/hooks/useEnableRemoteConfig';
import useEvent from 'src/hooks/useEvent';
import {
  isOnCameraAtom,
  sourceStreamAtom,
  statusAtom,
  toggleVideoTracksAtom,
} from 'src/stores/match/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { STATUS } from 'src/types/Match';
import { hexToRgb } from 'src/utils/common';

type ToggleCameraButtonTheme = 'light' | 'dark';
interface IToggleCameraButton {
  className?: string;
  buttonTheme?: ToggleCameraButtonTheme;
}

const ToolButton = styled(ToolButtonBase)<{
  buttonTheme?: ToggleCameraButtonTheme;
}>`
  background-color: ${({ theme, buttonTheme }) =>
    buttonTheme === 'light'
      ? 'transparent'
      : `rgba(${hexToRgb(theme.color.black)}, 0.3)`};
`;

const CameraOffButton: FC<IToggleCameraButton> = ({
  className,
  buttonTheme = 'light',
}) => {
  const isOnCamera = useAtomValue(isOnCameraAtom);
  const toggleVideoTracks = useSetAtom(toggleVideoTracksAtom);
  const pushEvent = useEvent();

  const onClick = useCallback(() => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.CAMERA_OFF_BUTTON_CLICK,
      eventParams: { isCameraOff: !isOnCamera },
    });
    toggleVideoTracks();
  }, [isOnCamera, pushEvent, toggleVideoTracks]);

  const enableWebCameraOff = useEnableRemoteConfig('enableWebCameraOff');
  const status = useAtomValue(statusAtom);
  const sourceStream = useAtomValue(sourceStreamAtom);

  const isShowCameraOffButton = useMemo(() => {
    if (!enableWebCameraOff) return false;
    if (
      status === STATUS.FINDING ||
      status === STATUS.CONNECTING ||
      !sourceStream
    )
      return false;
    return true;
  }, [enableWebCameraOff, sourceStream, status]);

  if (!isShowCameraOffButton) return null;

  return (
    <ToolButton
      className={className}
      onClick={onClick}
      buttonTheme={buttonTheme}
    >
      <ToolButtonIcon
        alt={isOnCamera ? 'camera-on' : 'camera-off'}
        src={`/images/icons/icCamera${isOnCamera ? '' : 'Off'}.svg`}
      />
    </ToolButton>
  );
};

export default CameraOffButton;
