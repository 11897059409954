import styled from '@emotion/styled';

import ProfileImage from 'src/components/ProfileImage';

export const RootStyle = styled.div<{ hasClick: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 8px 4px 0px;
  width: 100%;
  outline: none;
  background-color: transparent;
  ${({ hasClick }) => hasClick && 'cursor: pointer;'}
  ${({ theme }) => theme.screenSize.tablet} {
    min-height: 34px;
    width: 100%;
    padding: ${({ theme }) =>
      theme.isRtl ? '4px 0px 4px 80px' : '4px 80px 4px 0px'};
    width: 100%;
  }
`;
export const Profile = styled.div`
  width: 32px;
  height: 32px;
  padding: 4px;
`;
export const Icon = styled(ProfileImage)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-top: 2px;
  ${({ theme }) => theme.screenSize.tablet} {
    margin-top: 0;
  }
`;
export const ChatContainer = styled.div`
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0px 0px;
  gap: 2px;
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
  }
`;
