import styled from '@emotion/styled';
import { useAtom } from 'jotai';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { activeDecoEffectAtom } from 'src/stores/deco/atom';
import {
  ClientEffect,
  DEFAULT_CLIENT_EFFECT,
  DecoEffect,
} from 'src/types/Deco';
import { mouseHoverCss } from 'src/utils/styled';

const RemoveButtonText = styled.span`
  color: ${({ theme }) => theme.color.white};
  font-size: 12px;
  font-weight: 700;
`;

const RemoveButton = styled.button`
  z-index: 1;
  position: absolute;
  ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 36px;
  top: 298px;
  display: flex;
  align-items: center;
  height: 31px;
  padding: 0px 10px;
  border-radius: 16px;
  background-color: rgba(34, 34, 34, 0.8);
  cursor: pointer;

  ${mouseHoverCss(`
    background-color: rgba(34, 34, 34, 1);
  `)}
  :disabled {
    ${mouseHoverCss(`
      background-color: rgba(34, 34, 34, 0.8);
    `)}
    ${RemoveButtonText} {
      opacity: 0.4;
    }
  }

  ${({ theme }) => theme.screenSize.tablet} {
    position: fixed;
    top: auto;
    ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 16px;
    bottom: 226px;
  }
`;

interface Props {
  handleClickEffect: (effect: DecoEffect) => void;
}

const RemoveEffectButton: FC<Props> = ({ handleClickEffect }) => {
  const { t } = useTranslation();
  const [activeFilter] = useAtom(activeDecoEffectAtom);

  return (
    <RemoveButton
      disabled={activeFilter?.effectId === ClientEffect.NONE}
      onClick={() => handleClickEffect(DEFAULT_CLIENT_EFFECT)}
    >
      <RemoveButtonText>{t('deco_studio_btn_remove')}</RemoveButtonText>
    </RemoveButton>
  );
};

export default RemoveEffectButton;
