import { useEffect, MutableRefObject } from 'react';

/**
 * body scroll lock을 걸었으나 하위 특정 요소 트리에는 스크롤을 허용하고 싶을 때 사용
 */
export function useAllowTouchMove(ref: MutableRefObject<HTMLElement | null>) {
  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const handleTouchMove = (event: TouchEvent) => {
      event.stopPropagation(); // Allow scroll but prevent propagation to avoid body scroll lock.
    };

    element.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      element.removeEventListener('touchmove', handleTouchMove);
    };
  }, [ref]);
}
