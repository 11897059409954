import { FC, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';

import IcStyleSvg from 'src/assets/images/icStudioStyleBold.svg';
import IcBeautySvg from 'src/assets/images/icStudioBeautyBold.svg';
import IcBackgroundSvg from 'src/assets/images/icStudioBackgroundBold.svg';
import IcFilterSvg from 'src/assets/images/icStudioFilterBold.svg';

import TabletComponent from 'src/components/TabletComponent';
import { activeDecoEffectAtom, decoListAtom } from 'src/stores/deco/atom';
import {
  DecoGroupName,
  DecoEffect,
  decoEffectGroupNameCrowdinKey,
} from 'src/types/Deco';

import EffectButton from './EffectButton';
import RemoveEffectButton from './RemoveEffectButton';
import { useTranslation } from 'react-i18next';
import { useAllowTouchMove } from 'src/hooks/useAllowTouchMove';

const MobileFilterContainer = styled(TabletComponent)`
  z-index: 10;
  flex-direction: column;
  ${({ theme }) => theme.screenSize.tablet} {
    display: flex;
  }
`;
const MobileFilterList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 16px;
  box-sizing: border-box;

  display: flex;
  gap: 8px;

  overflow-x: scroll;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const MobileBottomTabArea = styled.div`
  display: flex;
  height: 56px;
  width: 100%;
`;
const IconBase = ({ theme }: { theme: Theme }) => css`
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
  fill: ${theme.color.gray300__dkGray250};
`;
const IcStyle = styled(IcStyleSvg)`
  ${IconBase}
`;
const IcBeauty = styled(IcBeautySvg)`
  ${IconBase}
`;
const IcFilter = styled(IcFilterSvg)`
  ${IconBase}
`;
const IcBackground = styled(IcBackgroundSvg)`
  ${IconBase}
`;
const MobileBottomTab = styled.button<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.gray100__dkGray150};
  background-color: transparent;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.green500 : theme.color.gray300__dkGray250};
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;

  ${({ isActive, theme }) =>
    isActive &&
    `
    ${IcBackground}, ${IcBeauty}, ${IcStyle}, ${IcFilter} {
      fill: ${theme.color.green500};
    }
  `}
`;

const MobileFilterHeader = styled.div`
  padding: 16px 24px 0px 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
`;

interface Props {
  handleClickEffect: (effect: DecoEffect) => void;
}

const GROUP_TAB = [
  {
    icon: <IcStyle />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.STYLE],
    groupId: DecoGroupName.STYLE,
  },
  {
    icon: <IcBeauty />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.BEAUTY],
    groupId: DecoGroupName.BEAUTY,
  },
  {
    icon: <IcFilter />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.FILTER],
    groupId: DecoGroupName.FILTER,
  },
  {
    icon: <IcBackground />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.BACKGROUND],
    groupId: DecoGroupName.BACKGROUND,
  },
];

const MobileEffects: FC<Props> = ({ handleClickEffect }) => {
  const { t } = useTranslation();
  const activeEffect = useAtomValue(activeDecoEffectAtom);
  const decoList = useAtomValue(decoListAtom);
  const [activeGroupId, setActiveGroupId] = useState(DecoGroupName.STYLE);
  const effectListRef = useRef<HTMLUListElement>(null);

  useAllowTouchMove(effectListRef);

  const mobileList = useMemo(() => {
    const filterList = decoList.filter(
      (list) => list.groupId === activeGroupId
    );
    if (filterList.length > 0) {
      return filterList[0].effects;
    }

    return [];
  }, [activeGroupId, decoList]);

  const onClickTab = (groupId: DecoGroupName) => {
    setActiveGroupId(groupId);
  };

  const activeGroupName = useMemo(() => {
    const activeGroup = GROUP_TAB.find((v) => v.groupId === activeGroupId);
    if (activeGroup) return activeGroup.name;
    return null;
  }, [activeGroupId]);

  return (
    <MobileFilterContainer>
      <RemoveEffectButton handleClickEffect={handleClickEffect} />
      {activeGroupName && (
        <MobileFilterHeader>{t(activeGroupName)}</MobileFilterHeader>
      )}
      <MobileFilterList ref={effectListRef}>
        {mobileList.map((effect) => (
          <EffectButton
            key={effect.effectId}
            isNew={effect.newBadgeEnabled}
            effectId={effect.effectId}
            thumbnail={effect.thumbnailImageUrl}
            active={activeEffect?.effectId === effect.effectId}
            onClick={() => {
              handleClickEffect(effect);
            }}
          />
        ))}
      </MobileFilterList>
      <MobileBottomTabArea>
        {GROUP_TAB.map((item) => (
          <MobileBottomTab
            key={item.name}
            onClick={() => onClickTab(item.groupId)}
            isActive={activeGroupId === item.groupId}
          >
            {item.icon}
            {t(item.name)}
          </MobileBottomTab>
        ))}
      </MobileBottomTabArea>
    </MobileFilterContainer>
  );
};

export default MobileEffects;
